import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { PermissionConstants } from './shared/constants/permissions.constants';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'nurture-campaign',
    loadChildren: () => import('./nurture-campaign/nurture-campaign.module').then(m => m.NurtureCampaignModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'registration-info',
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'programs',
    loadChildren: () => import('./programs/programs.module').then(m => m.ProgramsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'donations',
    loadChildren: () => import('./donations/donations.module').then(m => m.DonationsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'campaigns',
    loadChildren: () => import('./campaigns/campaigns.module').then(m => m.CampaignsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'campaign/info/:campaignId',
    loadChildren: () => import('./campaigns/campaign-info/campaign-info.module').then(m => m.CampaignInfoModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'program/:programId',
    loadChildren: () => import('./program-info/program-info.module').then(m => m.ProgramInfoModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'venues',
    loadChildren: () => import('./venue/venue.module').then(m => m.VenueModule),
    canActivate: [AuthGuard],
    data: { permissions: [PermissionConstants.Permissions.UpdateVenue] }
  },
  {
    path: 'mail/content',
    loadChildren: () => import('./mail-content/mail-content.module').then(m => m.MailContentModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mail/action',
    loadChildren: () => import('./mail-action/mail-action.module').then(m => m.MailActionModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mail/search',
    loadChildren: () => import('./mail-search/mail-search.module').then(m => m.MailSearchModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'attendance',
    loadChildren: () => import('./attendance/attendance.module').then(m => m.AttendanceModule)
  },
  {
    path: 'programs-report',
    loadChildren: () =>
      import('./apac-programs-report/apac-programs-report.module').then(m => m.ApacProgramsReportModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'campaign/callees/:userId/:admin/:campaignId',
    loadChildren: () =>
      import('./campaigns/campaign-callees/campaign-callees.module').then(m => m.CampaignCalleesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'receipts',
    loadChildren: () => import('./receipts/receipts.module').then(m => m.ReceiptsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'webinars',
    loadChildren: () => import('./webinars/webinars.module').then(m => m.WebinarsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'program-type',
    loadChildren: () => import('./program-type/program-type.module').then(m => m.ProgramTypeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mail-template',
    loadChildren: () => import('./mail-template/mail-template.module').then(m => m.MailTemplateModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then(m => m.LoginModule),
  },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {}
