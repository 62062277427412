import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DefaultGridOptions } from '@app/shared/constants/global-constant';
import { GridOptions } from '@app/shared/entities/grid-options.entity';
import { AttendanceService } from '@app/shared/services/attendance/attendance.service';
import { DateConstants } from '@app/shared/services/date/date-constants';
import { DateService } from '@app/shared/services/date/date.service';
import { Member } from '@app/shared/services/member/member.entity';
import { MemberService } from '@app/shared/services/member/member.service';
import { Program } from '@app/shared/services/program/program.entity';
import { ProgramService } from '@app/shared/services/program/program.service';
import { RegistrationService } from '@app/shared/services/registration/registration.service';
import { CsvExportParams, GridApi, GridReadyEvent } from 'ag-grid-community';
import { merge, Observable, of } from 'rxjs';
import { filter, map, scan, switchMap } from 'rxjs/operators';
import { AddRegistrationDialogComponent } from './components/add-registration-dialog/add-registration-dialog.component';
import { columnDefs } from './program-info.constants';
import { ProgramInfoDto, ProgramInfoTemplate, ProgramInfoTemplateConstants } from './program-info.dto';
import { ProgInfoMemberDto } from './program-member.dto';

@Component({
  selector: 'app-program-info',
  templateUrl: './program-info.component.html',
  styleUrls: ['./shared/program-info.scss', './program-info.component.scss']
})
export class ProgramInfoComponent implements OnInit {
  constructor(
    private programService: ProgramService,
    private attendanceService: AttendanceService,
    private route: ActivatedRoute,
    private memberService: MemberService,
    public dialog: MatDialog,
    private dateService: DateService,
    private registrationService: RegistrationService
  ) {}

  gridApi: GridApi;
  programId: string;
  checkInEnabled = true; // todo
  showRegistrationGrid = false;
  gridOptions: GridOptions<ProgInfoMemberDto>;
  csvExportParams: CsvExportParams = {
    fileName: 'program-participants'
  };
  program$ = this.programService.program$.pipe(
    filter(obj => obj.object && Object.keys(obj.object).length > 0),
    map(p => this.mapToDto(p.object))
  );
  isProgramBeforeToday$: Observable<boolean> = this.program$.pipe(
    map(val => this.dateService.isBeforeToday(val.startYYYYMMDD?.toString()))
  );

  isProgramInLastOneWeek$: Observable<boolean> = this.program$.pipe(
    map(val =>
      this.dateService.isBeforeToday(
        this.dateService.formatDate(
          this.dateService.addWeeks(val.startYYYYMMDD?.toString(), 1),
          DateConstants.ClientFormat
        )
      )
    )
  );

  numCompleted$: Observable<number> = this.isProgramInLastOneWeek$.pipe(
    switchMap(isProgramInLastOneWeek => {
      if (isProgramInLastOneWeek || true) {
        this.registrationService.loadRegistrationSummary(this.programId);
        return this.registrationService.registrationSummary$(this.programId).pipe(
          filter(rs => rs?.object?.length > 0),
          map(rs => rs.object[0].numCompleted)
        );
      }
    })
  );

  programMembers$: Observable<ProgInfoMemberDto[]> = this.isProgramBeforeToday$.pipe(
    switchMap(isProgramBeforeToday => {
      if (isProgramBeforeToday) {
        this.memberService.loadMemberDetails(this.programId);
        return this.memberService.memberDetails$.pipe(
          filter(p => p?.object?.length >= 0),
          map(p => p.object.map(this.mapToMemberDto))
        );
      }
      return of([]);
    })
  );

  programInfoTemplate$: Observable<ProgramInfoTemplate> = merge(
    this.program$.pipe(map(data => this.templateSetter(ProgramInfoTemplateConstants.PROGRAM, data))),
    this.programMembers$.pipe(map(data => this.templateSetter(ProgramInfoTemplateConstants.PROGRAM_MEMBERS, data))),
    this.numCompleted$.pipe(map(data => this.templateSetter(ProgramInfoTemplateConstants.NUM_COMPLETED, data))),
    this.isProgramInLastOneWeek$.pipe(
      map(data => this.templateSetter(ProgramInfoTemplateConstants.IS_PROGRAM_IN_LAST_ONE_WEEK, data))
    )
  ).pipe(
    scan(
      (acc, val) => {
        const data = { ...acc, ...val };
        data.isCompleted = Object.keys(data).length - 1 === Object.keys(ProgramInfoTemplateConstants).length;
        return data;
      },
      { isCompleted: false } as ProgramInfoTemplate
    )
  );

  templateSetter(key: ProgramInfoTemplateConstants, data: any): ProgramInfoTemplate {
    return { [key]: data } as ProgramInfoTemplate;
  }

  ngOnInit(): void {
    this.gridOptions = { ...DefaultGridOptions, columnDefs };
    this.programId = this.route.snapshot.paramMap.get('programId');
    this.programService.loadProgramDetails(this.programId);
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
  }

  showAddRegistrationModal(): void {
    this.dialog.open(AddRegistrationDialogComponent, {
      width: '500px',
      data: {
        programId: this.programId,
        checkInEnabled: this.checkInEnabled
      }
    });
  }

  showRegistrations(): void {
    this.showRegistrationGrid = true;
  }

  mapToDto(program: Program): ProgramInfoDto {
    return {
      shortName: program?.shortName,
      startYYYYMMDD: program?.startYYYYMMDD,
      programId: program?.programId,
      fee: program?.fee
    } as ProgramInfoDto;
  }

  mapToMemberDto(member: Member): ProgInfoMemberDto {
    return {
      memberId: member.memberId,
      firstName: member.firstName,
      lastName: member.lastName,
      email: member.email,
      homePhone: member.homePhone,
      mobilePhone: member.mobilePhone,
      officePhone: member.officePhone,
      country: member.homeCountry,
      state: member.homeState
    } as ProgInfoMemberDto;
  }
}
