export class FeatureConstants {
  public static Features = {
    portal: {
      name: 'portal',
      actions: {
        logout: 'logout'
      }
    },
    program: {
      name: 'program',
      actions: {
        get: 'get',
        getFormFields: 'getFormFields',
        updateSessionReport: 'updateSessionReport',
        queryDetailed: 'queryDetailed',
        setDisabled: 'setDisabled',
        getAllProgramTypes: 'getAllProgramTypes',
        getAllVenues: 'getAllVenues',
        update: 'update',
        create: 'create'
      }
    },
    attendance: {
      name: 'attendance',
      actions: {
        getMembersForCheckin: 'getMembersForCheckin',
        getNumCheckins: 'getNumCheckins',
        checkinByETicket: 'checkinByETicket',
        checkin: 'checkin',
        getCheckInStatus: 'getCheckInStatus'
      }
    },
    ticket: {
      name: 'ticket',
      actions: {
        getStats: 'getStats',
        getAllRegisterInterests: 'getAllRegisterInterests',
        getAssistedBookingStats: 'getAssistedBookingStats',
        getBatchByETicketId: 'getBatchByETicketId',
        issueBatch: 'issueBatch',
        getExitStats: 'getExitStats'
      }
    },
    campaign: {
      name: 'campaign',
      actions: {
        get: 'get',
        getByUser: 'getByUser',
        query: 'query',
        emailCalleesList: 'emailCalleesList'
      }
    },
    interaction: {
      name: 'interaction',
      actions: {
        query: 'query',
        updateInteraction: 'updateInteraction',
        updateSubInteraction: 'updateSubInteraction',
        createSubInteraction: 'createSubInteraction',
        createInteraction: 'createInteraction'
      }
    },
    register: {
      name: 'register',
      actions: {
        get: 'get',
        query: 'query',
        getCount: 'getCount',
        getTagIssueCount: 'getTagIssueCount',
        getSummary: 'getSummary',
        getFormSubmittedCount: 'getFormSubmittedCount',
        registerForProgram: 'registerForProgram',
        getSummaryBatch: 'getSummaryBatch',
        issueTag: 'issueTag',
        clearTag: 'clearTag',
        overrideBayId: 'overrideBayId',
        checkinTag: 'checkinTag',
        checkoutTag: 'checkoutTag',
        setIeoCompleted: 'setIeoCompleted',
        sendPreCheckinForm: 'sendPreCheckinForm',
        sendETicketEmail: 'sendETicketEmail',
        getFilledFormIds: 'getFilledFormIds',
        getFormView: 'getFormView'
      }
    },
    ieo: {
      name: 'ieo',
      actions: {
        getProfile: 'getProfile',
        syncSMMRegistrations: 'syncSMMRegistrations'
      }
    },
    member: {
      name: 'member',
      actions: {
        query: 'query',
        detailedInfo: 'detailedInfo',
        updateGroupSubscription: 'updateGroupSubscription',
        create: 'create',
        delete: 'delete',
        merge: 'merge',
        getDuplicates: 'getDuplicates',
        ignoreDuplicate: 'ignoreDuplicate',
        assignDuplicateMergeWIP: 'assignDuplicateMergeWIP',
        updateContactDetails: 'updateContactDetails',
        getOtherLists: 'getOtherLists',
        removeUnverifiedProgram: 'removeUnverifiedProgram',
        quickSearch: 'quickSearch',
        addUnverifiedProgram: 'addUnverifiedProgram',
        addVerifiedProgram: 'addVerifiedProgram'
      }
    },
    donation: {
      name: 'purchase',
      actions: {
        query: 'query'
      }
    },
    inventory: {
      name: 'inventory',
      actions: {
        getCurrentStockList: 'getCurrentStockList',
        checkIn: 'checkIn',
        checkOut: 'checkOut',
        setKitchen3MealCount: 'setKitchen3MealCount'
      }
    },
    mail: {
      name: 'mail',
      actions: {
        createMailContent: 'createMailContent',
        updateMailContent: 'updateMailContent',
        queryMailContent: 'queryMailContent',
        getLists: 'getLists',
        getMailContent: 'getMailContent',
        viewStats: 'viewStats',
        search: 'search',
        getSendMailCount: 'getSendMailCount',
        sendEmailToMembers: 'sendEmailToMembers',
        sendToLoggedInUser: 'sendToLoggedInUser'
      }
    },
    mailSchedule: {
      name: 'mailSchedule',
      actions: {
        queryMailSequence: 'queryMailSequence',
        createMailSchedule: 'createMailSchedule',
        updateMailSchedule: 'updateMailSchedule',
        queryMailSchedule: 'queryMailSchedule'
      }
    },
    receipt: {
      name: 'receipt',
      actions: {
        query: 'query',
        generate: 'generate',
        resendEmail: 'resendEmail'
      }
    },
    venue: {
      name: 'venue',
      actions: {
        getAll: 'getAll',
        update: 'update',
        create: 'create'
      }
    },
    webinar: {
      name: 'webinar',
      actions: {
        get: 'get',
        query: 'query',
        queryParticipants: 'queryParticipants'
      }
    },
    volunteer: {
      name: 'volunteer',
      actions: {
        query: 'query'
      }
    }
  };
}
