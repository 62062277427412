import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackbarRef: MatSnackBarRef<NotificationComponent>
  ) {}

  ngOnInit(): void {
    if (this.data && this.data.msg) {
      // Remove quotes and trailing newlines
      this.data.msg = this.data.msg.replace(/^["'](.*)["']$/, '$1').replace(/\\n+$/, '');
    }
  }
}
