import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@app/auth/auth.service';
import { DefaultColumnDefFilterParam, DefaultGridOptions } from '@app/shared/constants/global-constant';
import { ColumnDef } from '@app/shared/entities/column-def.entity';
import { GridOptions } from '@app/shared/entities/grid-options.entity';
import {
  BtnCellRendererComponent
} from '@app/shared/grid/btn-cell-renderer/btn-cell-renderer.component';
import {
  HyperlinkCellRendererComponent,
  HyperlinkCellRendererParams,
  Target
} from '@app/shared/grid/hyperlink/hyperlink-cell-renderer.component';
import { AppRouteService } from '@app/shared/services/app-route/app-route.service';
import { DateConstants } from '@app/shared/services/date/date-constants';
import { DateService } from '@app/shared/services/date/date.service';
import { Program } from '@app/shared/services/program/program.entity';
import { Registration } from '@app/shared/services/registration/registration.entity';
import { RegistrationService } from '@app/shared/services/registration/registration.service';
import { ArrayUtil } from '@app/shared/utils';
import { CsvExportParams, GridApi, GridReadyEvent } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddRegistrationDialogComponent } from '../components/add-registration-dialog/add-registration-dialog.component';
import { CancelledRegistrationsComponent } from '../components/cancelled-registrations/cancelled-registrations.component';
import { PendingRegistrationsComponent } from '../components/pending-registrations/pending-registrations.component';
import { RegistrationStatus } from '../program-info.constants';
import { ProgInfoRegistrationDto } from './program-registration.dto';

@Component({
  selector: 'app-program-registrations',
  templateUrl: './program-registrations.component.html',
  styleUrls: ['../shared/program-info.scss', './program-registrations.component.scss']
})
export class ProgramRegistrationComponent implements OnInit {
  @Input() program: Program;
  @Input() programId: string;
  @Input() numCompleted: number;
  @Input() isProgramInLastOneWeek: boolean;

  columnDefs: Array<ColumnDef<ProgInfoRegistrationDto>> = [
    {
      headerName: 'Date',
      field: 'registeredOn',
      filter: 'date',
      type: 'date',
      // valueFormatter: (params: ValueFormatterParams<ProgInfoRegistrationDto>) => params.data.registeredMs.toString()
    },
    { headerName: 'ID', field: 'registrationId', cellClass: 'text-center', filterParams: DefaultColumnDefFilterParam },
    { headerName: 'First Name', field: 'firstName', filterParams: DefaultColumnDefFilterParam },
    { headerName: 'Last Name', field: 'lastName', filterParams: DefaultColumnDefFilterParam },
    { headerName: 'Email', field: 'email', filterParams: DefaultColumnDefFilterParam },
    {
      headerName: 'Mobile Phone',
      field: 'mobilePhone',
      cellRenderer: 'hyperLinkRenderer',
      filterParams: DefaultColumnDefFilterParam,
      cellRendererParams: {
        textProperty: 'mobilePhone',
        hrefDynamic: (registration: ProgInfoRegistrationDto) => `tel:${registration.mobilePhone}`
      } as HyperlinkCellRendererParams<ProgInfoRegistrationDto>
    },
    { headerName: 'City', field: 'city', filterParams: DefaultColumnDefFilterParam },
    { headerName: 'State', field: 'state', filterParams: DefaultColumnDefFilterParam },
    { headerName: 'Shambhavi', field: 'shambhavi', filterParams: DefaultColumnDefFilterParam },
    { headerName: 'SCK', field: 'sck', filterParams: DefaultColumnDefFilterParam },
    { headerName: 'Amt', field: 'amount', filterParams: DefaultColumnDefFilterParam },
    { headerName: 'Postal Code', field: 'postalCode', filterParams: DefaultColumnDefFilterParam },
    { headerName: 'Home Phone', field: 'homePhone', filterParams: DefaultColumnDefFilterParam },
    { headerName: 'Country', field: 'country', filterParams: DefaultColumnDefFilterParam },
    {
      headerName: 'Member ID',
      field: 'memberId',
      cellClass: 'text-center',
      filterParams: DefaultColumnDefFilterParam,
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        textProperty: 'memberId',
        hrefProperty: `memberIdLink`,
        target: Target.blank
      } as HyperlinkCellRendererParams<ProgInfoRegistrationDto>
    },
    { field: 'gender', cellClass: 'text-center', filterParams: DefaultColumnDefFilterParam },
    { headerName: 'Food Allergies', field: 'foodAllergies', filterParams: DefaultColumnDefFilterParam },
    {
      headerName: 'Txn ID',
      field: 'transactionId',
      cellClass: 'text-center',
      filterParams: DefaultColumnDefFilterParam
    },
  ];

  constructor(
    public dialog: MatDialog,
    private registrationService: RegistrationService,
    private dateService: DateService,
    private appRouteService: AppRouteService,
    private auth: AuthService,
  ) {}

  checkInEnabled = true; // todo
  registrations$: Observable<ProgInfoRegistrationDto[]>;
  rowData = [] as ProgInfoRegistrationDto[];
  cancellations = [] as ProgInfoRegistrationDto[];
  cancellationsCount = 0;
  pendingRegistrations = [] as ProgInfoRegistrationDto[];
  pendingRegistrationsCount = 0;
  gridApi: GridApi;
  gridOptions: GridOptions<ProgInfoRegistrationDto>;
  csvExportParams: CsvExportParams = {
    fileName: 'program-registrations'
  };

  ngOnInit(): void {
    this.gridOptions = {
      ...DefaultGridOptions,
      columnDefs: this.columnDefs,
      components: {
        btnCellRenderer: BtnCellRendererComponent,
        hyperLinkRenderer: HyperlinkCellRendererComponent
      }
    };

    this.csvExportParams.fileName = this.program?.shortName;

    this.registrations$ = this.registrationService
      .queryRegistration(this.programId, true, true)
      .pipe(
        map(p =>
          p?.object
            ?.map(reg => this.mapToRegistrationDto(reg))
            ?.sort((a, b) => ArrayUtil.sort<ProgInfoRegistrationDto>(a, b, 'registeredMs'))
        )
      );

    this.registrations$.subscribe(registrations => {
      if (this.isProgramInLastOneWeek) {
        if (!registrations) {
          return;
        }
        this.rowData = registrations.filter(a => a.status === RegistrationStatus.RegistrationComplete);
        this.cancellations = registrations.filter(a => a.status === RegistrationStatus.Cancelled);
        this.cancellationsCount = this.cancellations.length;
        this.pendingRegistrations = registrations.filter(
          a => a.status !== RegistrationStatus.RegistrationComplete && a.status !== RegistrationStatus.Cancelled
        );
        this.pendingRegistrationsCount = this.pendingRegistrations.length;
      }
    });
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
  }

  mapToRegistrationDto(registration: Registration): ProgInfoRegistrationDto {
    return {
      registeredOn: this.dateService.formatDate(registration.registeredMs, DateConstants.ProgramDateFormat),
      registeredMs: registration.registeredMs,
      registrationId: registration.registrationId,
      status: registration.status,
      firstName: registration.firstName,
      lastName: registration.lastName,
      email: registration.email,
      mobilePhone: registration.mobilePhone,
      city: registration.city,
      state: registration.state,
      shambhavi: registration.customFields.shambhavi,
      sck: registration.customFields.sck,
      amount: registration.amount,
      postalCode: registration.postalCode,
      country: registration.country,
      memberId: registration.memberId,
      memberIdLink: this.appRouteService.getMemberProfileUrl(registration?.memberId?.toString()),
      programId: registration.programId,
      gender: registration.gender,
      transactionId: registration.transactionId,
      updatedAt: this.dateService.formatDate(registration.updatedMs, DateConstants.ProgramDateTimeFormat),
      foodAllergies: registration.customFields.foodAllergies,
    } as ProgInfoRegistrationDto;
  }

  showAddRegistrationModal(): void {
    this.dialog.open(AddRegistrationDialogComponent, {
      width: '500px',
      data: {
        programId: this.programId,
        checkInEnabled: this.checkInEnabled
      }
    });
  }

  showCancelledRegistrationModal(): void {
    this.dialog.open(CancelledRegistrationsComponent, {
      width: '800px',
      data: {
        rowData: this.cancellations
      }
    });
  }

  showPendingRegistrationModal(): void {
    this.dialog.open(PendingRegistrationsComponent, {
      width: '800px',
      data: {
        rowData: this.pendingRegistrations
      }
    });
  }

  get canViewHealthInfo(): boolean {
    return this.auth.hasPermission('HYPGM_SDATA');
  }

  get healthInfoLink(): string {
    return `/program/${this.programId}/health`;
  }
}
