import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { JsonEditorSchemaService } from '../json-editor/json-editor-schema.component';
import { JsonEditorListData } from './json-editor-list.data';

@Component({
  selector: 'app-json-editor',
  templateUrl: './json-editor.component.html',
  styleUrls: ['./json-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class JsoneditorComponent implements OnInit{
  public editorOptions: JsonEditorOptions;
  
  
  @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;

    constructor(
    public dialogRef: MatDialogRef<JsoneditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: JsonEditorListData,
    public dialog: MatDialog,
    private jsonEditorSchemaService: JsonEditorSchemaService
  ) { 
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.mode = 'tree';
    this.editorOptions.schema = this.jsonEditorSchemaService.getSchema();
  }
 
  saveJson() {
    let str = JSON.stringify(this.cleanData(this.editor.get(),this.jsonEditorSchemaService.getSchema()))
    this.dialogRef.close(str);
  }

  ngOnInit() {
    this.data.configJson = this.mergeJsonWithSchema(this.data.configJson, this.jsonEditorSchemaService.getSchema());
  }

  mergeJsonWithSchema(jsonData: any, schema: any): any {
    if (!jsonData) jsonData = {};

    for (let key in schema.properties) {
      if (!jsonData.hasOwnProperty(key)) {
        switch (schema.properties[key].type) {
          case 'array':
            jsonData[key] = []; 
            break;
          case 'string':
            jsonData[key] = "";
            break;
          case 'number':
            jsonData[key] = 0;
            break;
          case 'integer':
            jsonData[key] = 0;
            break;
          case 'boolean':
            jsonData[key] = false;
            break;
          case 'object':
            jsonData[key] = this.mergeJsonWithSchema({}, schema.properties[key]);
            break;
          default:
            jsonData[key] = null;
        }
      } else if (schema.properties[key].type === 'object') {
        jsonData[key] = this.mergeJsonWithSchema(jsonData[key], schema.properties[key]);
      }
    }

    return jsonData;
  }

  cleanData(jsonData: any, schema: any): any {
    let result: any = {};

    for (let key in jsonData) {
      if (jsonData[key] !== null && jsonData[key] !== undefined) {
        let schemaType = schema.properties[key]?.type;

        if (schemaType) {
          let isDefaultValue = false;

          switch (schemaType) {
            case 'array':
              isDefaultValue = Array.isArray(jsonData[key]) && jsonData[key].length === 0;
              break;
            case 'string':
              isDefaultValue = jsonData[key] === "";
              break;
            case 'number':
            case 'integer':
              isDefaultValue = jsonData[key] === 0;
              break;
            case 'boolean':
              isDefaultValue = jsonData[key] === false;
              break;
            case 'object':
              let filteredObject = this.cleanData(jsonData[key], schema.properties[key]);
              if (Object.keys(filteredObject).length > 0) {
                result[key] = filteredObject;
              }
              continue;
            default:
              break;
          }

          if (!isDefaultValue) {
            result[key] = jsonData[key];
          }
        } else {
          result[key] = jsonData[key];
        }
      }
    }

    return result;
  }


  onClose(): void {
    this.dialogRef.close();
  }
}
