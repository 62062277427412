import { Injectable } from '@angular/core';
import { ProgramType } from '@app/reports/models/program-type';
import { ApiResponseModel } from '@app/shared/api-response.model';
import { FeatureConstants } from '@app/shared/constants/feature.constants';
import { AppClient } from '@app/shared/constants/global-constant';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { DateService } from '../date/date.service';
import { Program } from './program.entity';

@Injectable({
  providedIn: 'root'
})
export class ProgramService extends BaseService {
  constructor(private dateService: DateService) {
    super();
  }

  client = AppClient.isha;
  feature = FeatureConstants.Features.program.name;

  private detailedProgram = new BehaviorSubject<ApiResponseModel<Program[]>>({} as ApiResponseModel<Program[]>);
  public detailedProgram$ = this.detailedProgram.asObservable();

  private program = new BehaviorSubject<ApiResponseModel<Program>>({} as ApiResponseModel<Program>);
  public program$ = this.program.asObservable();

  private allProgramTypes = new BehaviorSubject<ApiResponseModel<ProgramType[]>>({} as ApiResponseModel<ProgramType[]>);
  public allProgramTypes$ = this.allProgramTypes.asObservable();

  cachedQueryDate = { start: this.dateService.subMonths(1), end: this.dateService.addMonths(4) };

  loadQueryDetailed(startDate = '', endDate = '', groupIds = [], getStats = false, programTypeId = []): void {
    this.get<Program[]>(this.endpoint(true), {
      action: FeatureConstants.Features.program.actions.queryDetailed,
      startYYYYMMDD: this.dateService.formatFromDatePicker(startDate),
      endYYYYMMDD: this.dateService.formatFromDatePicker(endDate),
      groupIds,
      getStats,
      programTypeId
    }).subscribe(
      program => {
        this.detailedProgram.next(program);
      },
      error => console.log(`Error: ${error}`)
    );
  }

  loadProgramDetails(programId: string): void {
    this.get<Program>(this.endpoint(true), {
      action: FeatureConstants.Features.program.actions.get,
      programId,
      getSessionReport: true
    }).subscribe(
      program => {
        this.program.next(program);
      },
      error => console.log(`Error: ${error}`)
    );
  }

  getProgram(programId: string): Observable<ApiResponseModel<Program>> {
    return this.get<Program>(this.endpoint(true), {
      action: FeatureConstants.Features.program.actions.get,
      programId
    });
  }

  updateProgram(params: Program): Observable<ApiResponseModel<Program>> {
    return this.post<Program>(this.endpoint(true), {
      action: FeatureConstants.Features.program.actions.update,
      ...params
    });
  }

  createProgram(params: Program): Observable<ApiResponseModel<Program>> {
    return this.post<Program>(this.endpoint(true), {
      action: FeatureConstants.Features.program.actions.create,
      ...params
    });
  }

  loadAllProgramTypes(useCache = true): void {
    if (this.hasLoadedFromCache(this.allProgramTypes, useCache)) {
      return;
    }

    this.getAllProgramTypes().subscribe(
      programTypes => {
        this.allProgramTypes.next(programTypes);
      },
      error => console.log(`Error: ${error}`)
    );
  }

  getAllProgramTypes(): Observable<ApiResponseModel<ProgramType[]>> {
    return this.get<ProgramType[]>(this.endpoint(), {
      action: FeatureConstants.Features.program.actions.getAllProgramTypes
    });
  }

  disableProgram(programId: number, disabled: boolean): Observable<ApiResponseModel<Program>> {
    return this.get<Program>(this.endpoint(true), {
      action: FeatureConstants.Features.program.actions.setDisabled,
      programId,
      disabled
    });
  }
}
